import type { Ref } from 'vue'
import { useState } from '#app'

type SelectedCustomer = {
    id: string
    firstName: string
    commaName: string
}

// State for the currently selected customer's graph ID
// For the profile view when multiple customer's can be attached to one profile
export function useSelectedCustomer() {
    const selectedCustomer = useState<SelectedCustomer | null>(
        'selectedCustomer',
        () => null
    )
    if (selectedCustomer.value === null) {
        throw new Error()
    }
    return selectedCustomer as Ref<SelectedCustomer>
}

export function useSetSelectedCustomer() {
    const selectedCustomer = useState<SelectedCustomer | null>(
        'selectedCustomer',
        () => null
    )
    return (newValue: SelectedCustomer) => {
        selectedCustomer.value = newValue
    }
}
